var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    {
      staticClass: "m-0 px-8 pt-6",
      attrs: { id: "requests-wrapper", fluid: "" },
    },
    [
      _c(
        "v-row",
        {
          staticStyle: { "align-items": "baseline" },
          attrs: { "no-gutters": "" },
        },
        [
          _c(
            "v-col",
            { staticClass: "pa-0 ma-0", attrs: { cols: "6", fluid: "" } },
            [
              _c(
                "h3",
                { staticClass: "text-h3 mb-0", attrs: { id: "title-page" } },
                [_vm._v(_vm._s(_vm.$t("requests.title-page")))]
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _vm.displaySkeleton
            ? [_c("TeamRequestListSkeleton")]
            : _vm.teamRequests.length <= 0 && !_vm.displaySkeleton
            ? _c("NoContent")
            : [
                _c(
                  "v-container",
                  {
                    staticClass: "mx-0 pl-0 pt-0 pb-0",
                    attrs: { id: "request-list", fluid: "" },
                  },
                  [
                    _c(
                      "v-row",
                      {
                        staticClass: "mb-8",
                        staticStyle: {
                          "justify-content": "flex-end !important",
                        },
                        attrs: { "no-gutters": "" },
                      },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "3" } },
                          [_c("SNotification")],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.teamRequests.length > 0 && !_vm.displaySkeleton
                      ? _c(
                          "div",
                          [
                            _c("FilterCards", {
                              attrs: {
                                teamRequests: _vm.teamRequests,
                                onClick: _vm.handleStatusFilterSelection,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("TeamRequestList", {
                  attrs: {
                    teamRequestDisplayed: _vm.teamRequestsFiltered,
                    openRequestDetailModal: _vm.openRequestDetailModal,
                    openCancelModal: _vm.openCancelModal,
                    statusFilterSelected: _vm.statusFilterSelected,
                  },
                }),
              ],
        ],
        2
      ),
      _vm._v(" "),
      _c("SModalCentered", {
        attrs: {
          isOpen: _vm.isCancelModalOpen,
          title: _vm.$t("requests.cancel-title", {
            name: _vm.cancelModalTitleName,
          }),
          maxWidth: "500px",
          closeModal: _vm.closeCancelModal,
          customProps: { bodyPaddingY: 0, fullWidthFooter: true },
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c("div", { staticClass: "text-subtitle-1" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("requests.cancel-message-textbody")) +
                      "\n      "
                  ),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "6" } },
                      [
                        _c("sButton", {
                          attrs: {
                            btnStyle: "tertiary",
                            sText: "requests.cancel-text",
                            action: _vm.closeCancelModal,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      { attrs: { cols: "6" } },
                      [
                        _c("sButton", {
                          attrs: {
                            btnStyle: "quaternary",
                            sText: "requests.delete-button",
                            action: _vm.cancelTeamRequest,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("SPopUp", {
        staticClass: "popUp",
        attrs: {
          isOpen: _vm.isSuccessPopUpOpen,
          sText: _vm.successPopUpText,
          isValid: true,
        },
        on: { close: _vm.closeSuccessPopUp },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
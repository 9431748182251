import { render, staticRenderFns } from "./ClientSelector.vue?vue&type=template&id=356ef1e5&scoped=true"
import script from "./clientSelector.ts?vue&type=script&lang=ts&external"
export * from "./clientSelector.ts?vue&type=script&lang=ts&external"
import style0 from "./ClientSelector.vue?vue&type=style&index=0&id=356ef1e5&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "356ef1e5",
  null
  
)

export default component.exports